import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AnimatedCursor from "react-animated-cursor";
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      {/* <AnimatedCursor
        innerSize={8}
        outerSize={16}
        color='30, 58, 138'
        outerAlpha={0.2}
        innerScale={1.7}
        outerScale={7}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link'
        ]}
      /> */}
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
import React from 'react';
import innoflow from '../images/clients/innoflow.svg';
// import walton from '../images/clients/walton.svg';
// import rlf from '../images/clients/rfl.svg';
import tech8 from '../images/clients/tech8.svg';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div className="mt-8 bg-white">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center sm:text-3xl text-2xl text-sky-500 uppercase font-bold ">Our Clients</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-sky-500'></div>
                    </div>
                </div>

                <div className="lg:p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid xsm:grid-cols-2 xsm:px-8 sm:grid-cols-2 lg:grid-cols-2 space-y-4 sm:space-y-0">
                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 transform hover:scale-102 hover:shadow-2xl">
                            <img src={tech8} alt="client" className='h-32 w-32 md:w-1/2 lg:w-1/3' />
                        </div>
                        <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out opacity-100 w-1/6 transform hover:scale-102 hover:shadow-2xl">
                            <img src={innoflow} alt="client" className='h-32 w-32 md:w-1/2 lg:w-1/3' />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Clients;
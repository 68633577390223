import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import "./index.css"

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-sky-500" smooth to="/#about">
                About
            </HashLink>

            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-sky-500" smooth to="/#services">
                Services
            </HashLink>

            <div className="dropdown inline-block relative">
                <button className="px-4 font-extrabold text-gray-500 hover:text-sky-500">
                    Teams
                </button>
                <div className="dropdown-menu absolute hidden text-gray-700 pt-1">
                    <Link className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" to="/bangladesh">Bangladesh</Link>
                    <Link className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" to="/uk">UK</Link>
                    <Link className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" to="/taiwan">Taiwan</Link>
                    <Link className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" to="/luxembourg">Luxembourg</Link>
                </div>
            </div>

            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-sky-500" to="/contact">
                Contact Us
            </HashLink>
        </>
    );
};

export default NavLinks;
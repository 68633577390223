import React, { useState } from 'react';
import img from '../images/web.svg';
import img3 from '../images/cad.svg';
import img4 from '../images/consultation.svg';

const Services = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const renderAccordionItem = (index, image, title, content, details) => (
    <div
      key={index}
      className={`transition-all ease-in-out duration-400 overflow-hidden text-gray-700 ${openAccordion === index ? 'bg-white text-white ' : 'bg-white'
        } rounded-lg shadow-2xl p-8 group mb-4 cursor-pointer accordion-item`}
      onClick={() => handleAccordionClick(index)}
    >
      <div className="m-2 text-justify text-sm">
        <img alt="card img" className="rounded-t w-full h-64 object-cover" src={image} />
        <h2 className="font-semibold my-4 text-2xl text-center">{title}</h2>
        <p className="text-md font-medium text-left">{content}</p>
      </div>
      <div
        className={`transition-all ease-in-out duration-700 overflow-hidden ${openAccordion === index ? 'max-h-screen' : 'max-h-0'}`}
      >
        <div className="border-t border-gray-800 my-4"></div> {/* Dark gray line */}
        <div className="mb-1 text-center text-sm text-bold">
          {details.map((detail, detailIndex) => (
            <p key={detailIndex} className="mb-1">
              {detail}
            </p>
          ))}
        </div>
      </div>
      {/* Toggle button (up or down) */}
      <div className="flex justify-center mt-4">
        <button
          className="text-sky-500 focus:outline-none"
          onClick={() => handleAccordionClick(index)}
        >
          {openAccordion === index ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
              <path d="M12 7.414L17.293 12.707 18.707 11.293 12 5.586 5.293 11.293 6.707 12.707z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
              <path d="M12 14.585L6.707 9.293 5.293 10.707 12 17.414 18.707 10.707 17.293 9.293z" />
            </svg>
          )}
        </button>
      </div>
    </div>
  );

  const accordionData = [
    {
      image: img3,
      title: 'CAD Services',
      content: 'Elevate projects with precision in 2D technical drawing, 3D CAD modeling, and solutions in industrial design, electro-mechanical design, DFMA & FEA.',
      details: ['2D Technical Drawing & Drafting', '3D CAD Modelling', 'Industrial Design', 'Electro-Mechanical Design', 'Engineering Simulation', 'Prototyping', 'Additive Manufacturing', 'Project Management & Implementation', 'MEP', 'Plant Design'],
    },
    {
      image: img,
      title: 'Code Services',
      content: 'Specializing in custom website creation and optimization for businesses of all sizes. Building mobile-friendly and user-friendly websites and applications.',
      details: ['IT Consulting & Infrastructure Solutions', 'Software Development & Customization', 'Mobile App Development', 'QA & Test Automation'],
    },
    {
      image: img4,
      title: 'Common IT Services',
      content: 'Unlock technology potential with IoT consultancy, design, MVP services, and offshore team development. Shaping innovative solutions tailored to business needs.',
      details: ['IT Consultancy & Design', 'MVP Service', 'Offshore Team Development'],
    }

  ];
  return (
    <div id="services" className="bg-gray-100 py-6 mx-8 sm:px-12" >
      <section>
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-sky-500 uppercase font-bold">services</h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-sky-700"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-sky-600">
            We are deeply committed to the growth and success of our clients.
          </h2>
        </div>

        <div className="py-8 xl:px-48 md:px-36 sm:px-24 xsm:px-12">
          <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-8" data-aos="zoom-in">
            {accordionData.map((item, index) => (
              <div key={index}>{renderAccordionItem(index, item.image, item.title, item.content, item.details)}</div>
            ))}
          </div>
        </div>
      </section>


      <section>
        <div className="m-auto max-w-6xx lg:p-2 md:p-12 h-5/6">
          <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left xsm:p-4" data-aos="zoom-in">
            <div className="max-w-screen flex flex-col lg:mx-4 justify-center">
              <div className='text-sky-500 mb-4'>
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
              </div>
              <h3 className="text-3xl  text-sky-500 
                            font-bold">Our <span className='font-black text-blue-900'>Mission</span></h3>
              <div>
                <p className='my-3 text-xl text-gray-600 font-semibold'>
                  Empowering businesses through innovative engineering and IT solutions. Our tailored services, strategic partnerships, and skilled team drive positive impacts in diverse industries.</p>
              </div>
            </div>
            <div className="max-w-screen flex flex-col lg:mx-4 justify-center">
              <div className='text-sky-500 mb-4'>
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 7l-3.36-2.171c-.405.625-.64 1.371-.64 2.171 0 2.209 1.791 4 4 4s4-1.791 4-4-1.791-4-4-4c-.742 0-1.438.202-2.033.554l2.033 3.446z" /></svg>
              </div>
              <h3 className="text-3xl  text-sky-500 font-bold">Our <span className='font-black text-blue-900'>Vision</span></h3>
              <div>
                <p className='my-3 text-xl text-gray-600 font-semibold'>We strive to lead globally in engineering and IT solutions, driving industry transformation through CAD, software development, and IT consulting expertise.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Services;



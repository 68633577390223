import React from 'react';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Services from '../components/Services';
import Tech from '../components/Tech';
import ScrollToTop from '../components/ScrollToTop';
import ScrollToTopButton from '../components/ScrollToTopButton';
import Clients from '../components/Clients';
import Cta from '../components/Cta';

import { Helmet } from 'react-helmet-async'; // Import Helmet

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Home Page</title>
                <meta name="CAD&CODES" content="This is the home page of our CAD&CODES." />
            </Helmet>
            <ScrollToTop>
                <Hero />
                <Intro />
                <div className = "bg-gray-100">
                    <Services />
                </div>
                <Tech />
                <Clients />
                <Cta />
                <Footer />
                <ScrollToTopButton />
            </ScrollToTop>
        </>
    )
}

export default Home;
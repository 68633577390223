import React from 'react';
import NavBar from "../components/Navbar/NavBar";
import './waves.css'

const UKContact = () => {
    return (
        <>
            <NavBar />
            <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12 relative overflow-hidden">
                <div class="ocean">
                    <div class="wave"></div>
                    <div class="wave"></div>
                </div>
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                    <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                        <div className="max-w-md mx-auto">
                            <div className="flex items-center space-x-5">
                                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                                    <h2 className="leading-relaxed">Sales & Technical Support - UK</h2>
                                </div>
                            </div>
                            <div className="divide-y divide-gray-200">
                                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <p>+44 7466 673528</p>
                                    <p>5, Gaverick Mews, London, E14 3AL</p>
                                    <p>contact.uk@cadandcodes.com </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UKContact;
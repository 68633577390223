import React, { useEffect } from 'react';
import InfiniteHorizontalScroll from './InfiniteHorizontalScroll';

import threedx from '../images/cad-tech/3dx_logo.svg';
import ansys from '../images/cad-tech/ansys-logo.svg';
import autodesk from '../images/cad-tech/autodesk-autocad_logo.svg';
import autodesk_inventory from '../images/cad-tech/autodesk-inventor.svg';
import autodesk_fusion from '../images/cad-tech/autodesk-fusion-360-logo.svg';
import comsol from '../images/cad-tech/comsol-logo.svg';
import solidworks from '../images/cad-tech/dss-solidworks-logo.svg';
import freecad from '../images/cad-tech/freecad-logo.svg';
import keyshot from '../images/cad-tech/keyshot-logo.svg';
import siemens from '../images/cad-tech/siemens-nx_logo.svg';

import aws from '../images/code-tech/aws.svg';
import django from '../images/code-tech/django.svg';
import flutter from '../images/code-tech/flutter.svg';
import java from '../images/code-tech/java.svg';
import mongo from '../images/code-tech/mongo.svg';
import mysql from '../images/code-tech/mysql.svg';
import node from '../images/code-tech/node.svg';
import python from '../images/code-tech/python.svg';
import react from '../images/code-tech/react.svg';
import redis from '../images/code-tech/redis.svg';


const Tech = () => {

    const cadimages = [threedx, ansys, autodesk, autodesk_fusion, autodesk_inventory, comsol, solidworks, freecad, keyshot, siemens];
    const codeimages = [react, flutter, node, django, mongo, mysql, python, java, aws, redis]


    return (
        <div className="mt-8 bg-white px-16">
            <section data-aos="fade-up">
                <div className="my-4 py-4 ">
                    <h2 className="my-2 text-center sm:text-3xl text-2xl text-sky-500 uppercase font-bold ">Technologies we Work with</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-sky-500'></div>
                    </div>
                </div>

                <div className="xsm:py-4">
                    <div className="flex flex-col gap-4 sm:gap-0 sm:p-4 md:p-20" data-aos="fade-in" data-aos-delay="600">
                        <div className="sm:p-4 mb-6">
                            <InfiniteHorizontalScroll images={cadimages} direction='left' speed={1500} />
                        </div>
                        <div className="sm:p-4 md:pb-16"></div>
                        <div className="sm:p-4 md:pb-16">
                            <InfiniteHorizontalScroll images={codeimages} direction='right' speed={1500} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Tech;


//  <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2 "> 
//  <div className="lg:pt-0">
//                             <div className="sm:border sm:rounded-lg sm:overflow-hidden sm:shadow-lg sm:pt-6 pb-6">
//                                 <div className={`grid grid-cols-2 sm:grid-cols-2 ${isExpandedCode || isLargeScreenCode ? 'lg:grid-cols-5' : ''} place-items-center gap-y-10`} onClick={() => setIsExpandedCode(!isExpandedCode)}>
//                                     {initialImagesCode.map((img, index) => (
//                                         <div key={index} style={techImage} className="overflow-hidden flex justify-center transition-all ease-in-out hover:scale-110">
//                                             <img src={img} alt="client" className=' ' />
//                                         </div>
//                                     ))}
//                                     {(isExpandedCode || isLargeScreenCode) && additionalImagesCode.map((img, index) => (
//                                         <div key={index} style={techImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out hover:scale-110">
//                                             <img src={img} alt="client" className=' ' />
//                                         </div>
//                                     ))}
//                                 </div>
//                                 {!isLargeScreenCode && <div className="flex justify-center mt-4">
//                                     <button
//                                         className="text-sky-500 focus:outline-none mb-4"
//                                     >
//                                         {isExpandedCode ? (
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
//                                                 <path d="M12 7.414L17.293 12.707 18.707 11.293 12 5.586 5.293 11.293 6.707 12.707z" />
//                                             </svg>
//                                         ) : (
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
//                                                 <path d="M12 14.585L6.707 9.293 5.293 10.707 12 17.414 18.707 10.707 17.293 9.293z" />
//                                             </svg>
//                                         )}
//                                     </button>
//                                 </div>}
//                             </div>
//                         </div> */}


//  <div className="sm:pt-10 lg:pt-0">
//                             <div className="sm:border sm:rounded-lg sm:overflow-hidden sm:shadow-lg sm:pt-6 pb-6">
//                                 <div className={`grid grid-cols-2 sm:grid-cols-2 ${isExpanded || isLargeScreen ? 'lg:grid-cols-5' : ''} place-items-center gap-y-10`} onClick={() => setIsExpanded(!isExpanded)}>
//                                     {initialImagesCAD.map((img, index) => (
//                                         <div key={index} style={techImage} className="overflow-hidden flex justify-center transition-all ease-in-out hover:scale-110">
//                                             <img src={img} alt="client" className=' ' />
//                                         </div>
//                                     ))}
//                                     {(isExpanded || isLargeScreen) && additionalImagesCAD.map((img, index) => (
//                                         <div key={index} style={techImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out hover:scale-110">
//                                             <img src={img} alt="client" className=' ' />
//                                         </div>
//                                     ))}
//                                 </div>
//                                 {!isLargeScreen && <div className="flex justify-center mt-4">
//                                     <button
//                                         className="text-sky-500 focus:outline-none mb-4"
//                                     >
//                                         {isExpanded ? (
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
//                                                 <path d="M12 7.414L17.293 12.707 18.707 11.293 12 5.586 5.293 11.293 6.707 12.707z" />
//                                             </svg>
//                                         ) : (
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
//                                                 <path d="M12 14.585L6.707 9.293 5.293 10.707 12 17.414 18.707 10.707 17.293 9.293z" />
//                                             </svg>
//                                         )}
//                                     </button>
//                                 </div>}
//                             </div>
//                         </div> */}

//  </div> 
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { StyledEngineProvider } from '@mui/material'


const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                        {/* 1st block */}
                        <div className="col-span-12 lg:col-span-4">
                            <div className="box-border border-b-4 border-sky-900 p-8 bg-sky-500 text-gray-100 text-center rounded-lg xl:w-80 mx-auto opacity-90">
                                <h3 className="font-bold text-4xl mb-4">Contact Us</h3>
                                <div className='text-md font-medium text-gray-600'>
                                    <StyledEngineProvider injectFirst>
                                        <Accordion className="bg-gray-100 mb-1">
                                            <AccordionSummary>
                                                <h5 className="text-sm font-bold mb-2">Offshore Development Center - Bangladesh</h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p className="mb-1 text-sm">+880 1626-079276</p>
                                                <p className="mb-1 text-sm">Dhaka, Bangladesh</p>
                                                <p className="mb-4 text-sm">support@cadandcodes.com</p>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion className="bg-gray-100 mb-1">
                                            <AccordionSummary>
                                                <h5 className="text-sm font-bold mb-2">Sales & Technical Support - Taiwan</h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p className="mb-1 text-sm">+886 911 649 778</p>
                                                <p className="mb-1 text-sm">Taiwan City, Taiwan</p>
                                                <p className="mb-4 text-sm">contact.taiwan@cadandcodes.com </p>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion className="bg-gray-100 mb-1">
                                            <AccordionSummary>
                                                <h5 className="font-bold mb-2 text-sm">Sales & Technical Support - UK</h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p className="mb-1 text-sm">+44 7466 673528</p>
                                                <p className="mb-1 text-sm">5, Gaverick Mews, London, E14 3AL</p>
                                                <p className="mb-4 text-sm">contact.uk@cadandcodes.com </p>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion className="bg-gray-100 mb-1">
                                            <AccordionSummary>
                                                <h5 className="font-bold mb-2 text-sm">Sales & Technical Support - EU</h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p className="mb-1 text-sm">+352661920725</p>
                                                <p className="mb-1 text-sm">contact.eu@cadandcodes.com</p>
                                            </AccordionDetails>
                                        </Accordion>
                                    </StyledEngineProvider>
                                </div>
                            </div>
                        </div>

                        {/* 2nd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                            <h6 className="text-[#092739] text-xl font-bold mb-4">LINKS</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink to="/#about" className="text-[ #092739] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/#services" className="text-[ #092739] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/contact#contact" className="text-[ #092739] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>
                                </li>
                            </ul>
                        </div>

                        {/* 3rd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                            <h6 className="text-[ #092739] text-xl font-bold mb-4">OUR SERVICES</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink to="/#services" className="text-[ #092739] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Code Services</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/#services" className="text-[ #092739] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">CAD Services</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/#services" className="text-[ #092739] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Common Services</HashLink>
                                </li>
                            </ul>
                        </div>

                        {/* 4th block */}
                        <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-[#092739]">
                            <div className="text-xl mb-6">
                                Social Media Links.
                            </div>

                            <div className="text-md font-medium mb-6">
                                Follow us on social media.
                            </div>
                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <a href="#" className="flex justify-center items-center text-[#092739] hover:text-gray-500 transition duration-150 ease-in-out" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 32 32">
                                                <path d="M 7 5 C 5.90625 5 5 5.90625 5 7 L 5 25 C 5 26.09375 5.90625 27 7 27 L 25 27 C 26.09375 27 27 26.09375 27 25 L 27 7 C 27 5.90625 26.09375 5 25 5 Z M 7 7 L 25 7 L 25 25 L 19.8125 25 L 19.8125 18.25 L 22.40625 18.25 L 22.78125 15.25 L 19.8125 15.25 L 19.8125 13.3125 C 19.8125 12.4375 20.027344 11.84375 21.28125 11.84375 L 22.90625 11.84375 L 22.90625 9.125 C 22.628906 9.089844 21.667969 9.03125 20.5625 9.03125 C 18.257813 9.03125 16.6875 10.417969 16.6875 13 L 16.6875 15.25 L 14.0625 15.25 L 14.0625 18.25 L 16.6875 18.25 L 16.6875 25 L 7 25 Z"></path>
                                            </svg>
                                        </a>

                                    </li>
                                    <li className="ml-4">
                                        <a href="https://www.linkedin.com/company/cadandcodes" className="flex justify-center items-center text-[#092739] hover:text-gray-500 transition duration-150 ease-in-out" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 32 32">
                                                <path d="M 7.5 5 C 6.132813 5 5 6.132813 5 7.5 L 5 24.5 C 5 25.867188 6.132813 27 7.5 27 L 24.5 27 C 25.867188 27 27 25.867188 27 24.5 L 27 7.5 C 27 6.132813 25.867188 5 24.5 5 Z M 7.5 7 L 24.5 7 C 24.785156 7 25 7.214844 25 7.5 L 25 24.5 C 25 24.785156 24.785156 25 24.5 25 L 7.5 25 C 7.214844 25 7 24.785156 7 24.5 L 7 7.5 C 7 7.214844 7.214844 7 7.5 7 Z M 10.4375 8.71875 C 9.488281 8.71875 8.71875 9.488281 8.71875 10.4375 C 8.71875 11.386719 9.488281 12.15625 10.4375 12.15625 C 11.386719 12.15625 12.15625 11.386719 12.15625 10.4375 C 12.15625 9.488281 11.386719 8.71875 10.4375 8.71875 Z M 19.46875 13.28125 C 18.035156 13.28125 17.082031 14.066406 16.6875 14.8125 L 16.625 14.8125 L 16.625 13.5 L 13.8125 13.5 L 13.8125 23 L 16.75 23 L 16.75 18.3125 C 16.75 17.074219 16.996094 15.875 18.53125 15.875 C 20.042969 15.875 20.0625 17.273438 20.0625 18.375 L 20.0625 23 L 23 23 L 23 17.78125 C 23 15.226563 22.457031 13.28125 19.46875 13.28125 Z M 9 13.5 L 9 23 L 11.96875 23 L 11.96875 13.5 Z"></path>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">

                    </div>

                </div>

            </footer>
        </>
    )
}
export default Footer;

import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import ScrollToTop from './components/ScrollToTop';
import ContactLuxembourg from './pages/ContactLuxembourg';
import ContactBangladesh from './pages/ContactBd';
import ContactUK from './pages/ContactUK';
import ContactTaiwan from './pages/ContactTaiwan';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 600,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/luxembourg" element={<ContactLuxembourg />} />
            <Route path="/bangladesh" element={<ContactBangladesh />} />
            <Route path="/uk" element={<ContactUK />} />
            <Route path="/taiwan" element={<ContactTaiwan />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;

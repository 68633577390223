import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';

import 'swiper/css'; // Import Swiper CSS
import 'swiper/css/autoplay'
import 'swiper/css/free-mode'

// ... (Other necessary imports)

const InfiniteHorizontalScroll = ({ images, speed, direction }) => {
    const swiperRef = useRef(null);
    const [spaceBetween, setSpaceBetween] = useState(20);
    const [slidesPerView, setSlidesPerView] = useState(7);
    const [currentSlide, setCurrentSlide] = useState(0); // Add this line


    const handleSlideChange = () => {
        const newSlide = swiperRef.current.swiper.realIndex;
        setCurrentSlide((newSlide + images.length) % images.length);
    };

    useEffect(() => {
        const updateSpaceBetween = () => {
            if (window.innerWidth <= 640) { // Mobile
                setSpaceBetween(20); // Reduced space for mobile
                setSlidesPerView(5); // Reduced slides for mobile
            } else if (window.innerWidth <= 768) { // Tablet
                setSpaceBetween(20); // Reduced space for tablet
                setSlidesPerView(6); // Reduced slides for tablet
            } else { // Laptop/Desktop
                setSpaceBetween(20); // Same space for laptop/desktop
                setSlidesPerView(7); // Same slides for laptop/desktop
            }
        };

        window.addEventListener('resize', updateSpaceBetween);
        updateSpaceBetween();

        // Cleanup function
        return () => {
            window.removeEventListener('resize', updateSpaceBetween);
        };
    }, []); // Empty dependency array to run the effect only once on mount and unmount


    return (
        <Swiper
            ref={swiperRef}
            modules={[Autoplay, FreeMode]}
            autoplay={{
                delay: speed, // Set autoplay interval based on provided speed
                disableOnInteraction: false,
                direction: direction === 'left' ? 'prev' : 'next',
                reverseDirection: direction === 'right'
            }}
            loop={true} // Enable infinite looping
            spaceBetween={spaceBetween} // Adjusted based on screen size
            slidesPerView={slidesPerView} // Adjusted based on screen size
            onSlideChange={handleSlideChange}
        >
            {images.map((image, index) => (
                <SwiperSlide key={index}>
                    <img className="w-20 h-auto" src={image} alt={`Image ${index + 1}`} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default InfiniteHorizontalScroll;
